<template>
  <div class="p-contentStudy">
    <div class="c-section c-section--fluid c-section--dotted c-section--mb40">
      <h1>
        <img
          src="/assets/img/content/study/pic_mv01.jpg"
          alt="UCC COFFEE ACADEMY" />
      </h1>

      <div class="c-section__inner p-contentStudy__sectionInner">
        <h2 class="c-title c-title--mb8">
          コーヒーアカデミーって<br />
          どんなところ？
        </h2>
        <p class="c-lead3 c-lead3--grey01">
          UCCコーヒーアカデミーは日本初のコーヒー専門教育機関です。コーヒーの全てを体系的・段階的に<br />
          楽しく学ぶことができる講座をご用意しています。
        </p>
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->

    <div class="c-section c-section--fluid c-section--mb32">
      <h2 class="c-title2 c-title2--center c-title2--mb8">
        紹介動画
        <span class="c-title2__sub"> movie </span>
      </h2>

      <div class="p-contentStudy__outline">
        <button
          v-show="!playedVideo"
          ref="videoButton"
          type="button"
          class="p-contentStudy__outline__button"
          data-play-state="play"
          @click="playMovie"
          v-ripple>
          <img
            src="/assets/img/content/study/pic_movie01.png"
            class="p-contentStudy__outline__buttonThumbnail"
            alt="" />
        </button>
        <div class="p-contentStudy__outline__videoWrap">
          <video
            v-show="playedVideo"
            ref="video"
            class="p-contentStudy__outline__video"
            controls
            playsinline>
            <source
              src="/assets/video/content/study/movie01.mp4"
              type="video/mp4" />
            申し訳ありませんが、お使いの環境で動画を再生できません。
          </video>
        </div>
        <!-- /.p-contentStudy__outline__videoWrap -->
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->

    <div class="c-section c-section--fluid c-section--mb32">
      <h2 class="c-title2 c-title2--center c-title2--mb16">
        UCCコーヒーアカデミーで<br />
        できること
        <span class="c-title2__sub"> about </span>
      </h2>

      <img src="/assets/img/content/study/pic_about01.png" alt="" />

      <div class="c-section__inner p-contentStudy__sectionInner">
        <p>
          初心者からコーヒーを仕事にしたい方まで、レベルや目的に合わせて受講できます。
        </p>
        <p>
          講師は全員コーヒーのプロ。難しいことはわかりやすく、専門的なことはより丁寧に。実技指導も、きめ細かくアドバイスします。
        </p>
        <p>あなたが好きなコーヒーについて もっと深く学んでみませんか？</p>
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->

    <div
      class="c-section c-section--fluid c-section--dotted c-section--grey04Bg">
      <div class="c-section__inner p-contentStudy__sectionInner2">
        <h2 class="c-title6 c-title6--center c-title6--mb8">
          はじめての方はこちら
        </h2>

        <Button
          :href="
            $customUrlScheme.page(
              'standalone',
              'url=https://www.ucc.co.jp/academy/'
            )
          "
          style-type="secondary"
          fluid>
          アカデミーでコーヒーについて知る
        </Button>
      </div>
      <!-- /.c-section__inner -->

      <div class="c-section__inner p-contentStudy__sectionInner2">
        <h2 class="c-title6 c-title6--center c-title6--mb8">
          アカデミー受講者の方はこちら
        </h2>

        <Button
          :href="
            $customUrlScheme.page(
              'standalone',
              'url=https://ucc-coffee-academy.resv.jp/reserve/res_plan_list.php?kind=main_plan&x=1642595522'
            )
          "
          style-type="quaternary"
          fluid>
          コーヒーセミナーを探す
        </Button>
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->
  </div>
  <!-- /.p-contentStudy -->
</template>

<script>
export default {
  data: () => ({
    playedVideo: false
  }),

  beforeDestroy() {
    this.$refs.video.paused;
    this.playedVideo = false;
  },

  methods: {
    playMovie() {
      if (!this.$refs.video) return;
      this.playedVideo = true;
      this.$refs.video.play();
    }
  }
};
</script>
