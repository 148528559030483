var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-contentStudy" }, [
    _vm._m(0),
    _c("div", { staticClass: "c-section c-section--fluid c-section--mb32" }, [
      _vm._m(1),
      _c("div", { staticClass: "p-contentStudy__outline" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.playedVideo,
                expression: "!playedVideo",
              },
              { name: "ripple", rawName: "v-ripple" },
            ],
            ref: "videoButton",
            staticClass: "p-contentStudy__outline__button",
            attrs: { type: "button", "data-play-state": "play" },
            on: { click: _vm.playMovie },
          },
          [
            _c("img", {
              staticClass: "p-contentStudy__outline__buttonThumbnail",
              attrs: {
                src: "/assets/img/content/study/pic_movie01.png",
                alt: "",
              },
            }),
          ]
        ),
        _c("div", { staticClass: "p-contentStudy__outline__videoWrap" }, [
          _c(
            "video",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.playedVideo,
                  expression: "playedVideo",
                },
              ],
              ref: "video",
              staticClass: "p-contentStudy__outline__video",
              attrs: { controls: "", playsinline: "" },
            },
            [
              _c("source", {
                attrs: {
                  src: "/assets/video/content/study/movie01.mp4",
                  type: "video/mp4",
                },
              }),
              _vm._v(
                " 申し訳ありませんが、お使いの環境で動画を再生できません。 "
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._m(2),
    _c(
      "div",
      {
        staticClass:
          "c-section c-section--fluid c-section--dotted c-section--grey04Bg",
      },
      [
        _c(
          "div",
          { staticClass: "c-section__inner p-contentStudy__sectionInner2" },
          [
            _c(
              "h2",
              { staticClass: "c-title6 c-title6--center c-title6--mb8" },
              [_vm._v(" はじめての方はこちら ")]
            ),
            _c(
              "Button",
              {
                attrs: {
                  href: _vm.$customUrlScheme.page(
                    "standalone",
                    "url=https://www.ucc.co.jp/academy/"
                  ),
                  "style-type": "secondary",
                  fluid: "",
                },
              },
              [_vm._v(" アカデミーでコーヒーについて知る ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "c-section__inner p-contentStudy__sectionInner2" },
          [
            _c(
              "h2",
              { staticClass: "c-title6 c-title6--center c-title6--mb8" },
              [_vm._v(" アカデミー受講者の方はこちら ")]
            ),
            _c(
              "Button",
              {
                attrs: {
                  href: _vm.$customUrlScheme.page(
                    "standalone",
                    "url=https://ucc-coffee-academy.resv.jp/reserve/res_plan_list.php?kind=main_plan&x=1642595522"
                  ),
                  "style-type": "quaternary",
                  fluid: "",
                },
              },
              [_vm._v(" コーヒーセミナーを探す ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "c-section c-section--fluid c-section--dotted c-section--mb40",
      },
      [
        _c("h1", [
          _c("img", {
            attrs: {
              src: "/assets/img/content/study/pic_mv01.jpg",
              alt: "UCC COFFEE ACADEMY",
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "c-section__inner p-contentStudy__sectionInner" },
          [
            _c("h2", { staticClass: "c-title c-title--mb8" }, [
              _vm._v(" コーヒーアカデミーって"),
              _c("br"),
              _vm._v(" どんなところ？ "),
            ]),
            _c("p", { staticClass: "c-lead3 c-lead3--grey01" }, [
              _vm._v(
                " UCCコーヒーアカデミーは日本初のコーヒー専門教育機関です。コーヒーの全てを体系的・段階的に"
              ),
              _c("br"),
              _vm._v(" 楽しく学ぶことができる講座をご用意しています。 "),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h2",
      { staticClass: "c-title2 c-title2--center c-title2--mb8" },
      [
        _vm._v(" 紹介動画 "),
        _c("span", { staticClass: "c-title2__sub" }, [_vm._v(" movie ")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "c-section c-section--fluid c-section--mb32" },
      [
        _c("h2", { staticClass: "c-title2 c-title2--center c-title2--mb16" }, [
          _vm._v(" UCCコーヒーアカデミーで"),
          _c("br"),
          _vm._v(" できること "),
          _c("span", { staticClass: "c-title2__sub" }, [_vm._v(" about ")]),
        ]),
        _c("img", {
          attrs: { src: "/assets/img/content/study/pic_about01.png", alt: "" },
        }),
        _c(
          "div",
          { staticClass: "c-section__inner p-contentStudy__sectionInner" },
          [
            _c("p", [
              _vm._v(
                " 初心者からコーヒーを仕事にしたい方まで、レベルや目的に合わせて受講できます。 "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 講師は全員コーヒーのプロ。難しいことはわかりやすく、専門的なことはより丁寧に。実技指導も、きめ細かくアドバイスします。 "
              ),
            ]),
            _c("p", [
              _vm._v(
                "あなたが好きなコーヒーについて もっと深く学んでみませんか？"
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }